import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseApp = initializeApp({
	apiKey: "AIzaSyA-YpiIeRUwyxMcvzvMjAzbX5NwQthZ8Hg",
	authDomain: "t1paginaschat.firebaseapp.com",
	databaseURL: "https://t1paginaschat-default-rtdb.firebaseio.com",
	projectId: "t1paginaschat",
	storageBucket: "t1paginaschat.appspot.com",
	messagingSenderId: "608075123864",
	appId: "1:608075123864:web:8d00fde3ed8200ad39d4ed"
});

export const fireBaseSignIn = signInWithEmailAndPassword;
export const auth = getAuth(firebaseApp);
export default firebaseApp;