import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { Login } from './components/Login';
import Chat from './views/chat/Chat';
import Navbar from './components/Navbar';

import './styles/normal.css';

function App() {
	useEffect(() => {
	}, []);

	return (
		<Router>
			<Navbar/>
			<Routes>
				<Route path='/chat' exact element={<Chat />} />
				<Route path='/login' exact element={<Login />} />
				<Route path='*' element={<Chat />} />
			</Routes>
		</Router>
	);
}

export default App;