import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { auth, fireBaseSignIn } from "../FirebaseConfig";
import {UserAvatar, ChatAvatar, ChatSanborns,ChatSanbornsLogin} from './ChatAvatars'
import './login.css';



export function Login() {
	useEffect(() => {
		let authToken = sessionStorage.getItem('T1ChatToken');
		if (authToken) navigate("/chat");
	}, [])

	const navigate = useNavigate();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	function handleSubmit(event) {
		event.preventDefault();
		fireBaseSignIn(auth, username, password).then((credentials) => {
			// Store user info in a cookie
			sessionStorage.setItem('T1ChatToken', credentials._tokenResponse.refreshToken);
			// Redirect to the chat page
			navigate("/chat");
		}).catch((error) => {
			console.log(error.message);
		});
	}

	return (
		<div className="LoginContainer">
       <div className="LoginCard">
         <div className="LoginAvatar">
           < ChatSanbornsLogin/>
         </div>
         <form onSubmit={handleSubmit} className="LoginForm">
           <div className="LoginInput">
             <label htmlFor="username">Correo:</label>
             <input type="text" id="username" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />
           </div>
           <div className="LoginInput">
             <label htmlFor="password">Password:</label>
             <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
           </div>
           <input type="submit" value="Login" className="LoginSubmit" />
         </form>
       </div>
     </div>



	);
}

export function Logout() {
	useEffect(() => {
	}, [])

	const navigate = useNavigate();

	function handleLogout(event) {
		sessionStorage.removeItem('T1ChatToken');
		navigate("/login");
	}

	return (
		<span className="Logout">
			<button onClick={handleLogout}>Sing out</button>
		</span>
	);
}
