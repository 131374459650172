// Chat avatars

export const ChatAvatar = () =>
<svg width="60px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
	<g id="chat-claro" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
			<g id="Theme_Openai_CS" transform="translate(-793.000000, -691.000000)">
					<g id="Avatar-Copy" transform="translate(793.000000, 691.000000)">
							<rect id="Rectangle" fill="#000000" x="0" y="0" width="30" height="30" rx="4"></rect>
							<g id="User" transform="translate(5.000000, 4.000000)" fill="#FFFFFF" fill-rule="nonzero">
									<path d="M10.1913875,11.9565217 C11.8346562,11.9565217 13.2574078,11.3673878 14.4202372,10.2050271 C15.5826878,9.04285576 16.1722488,7.62086709 16.1722488,5.97807151 C16.1722488,4.33584406 15.5828773,2.91366597 14.4200478,1.75111593 C13.2572183,0.58913396 11.8344667,0 10.1913875,0 C8.5479295,0 7.12536731,0.58913396 5.96272731,1.75130529 C4.8000873,2.91347662 4.21052632,4.3356547 4.21052632,5.97807151 C4.21052632,7.62086709 4.8000873,9.04304512 5.96272731,10.2052165 C7.12574623,11.3671984 8.54849781,11.9565217 10.1913875,11.9565217 Z M6.99180676,2.77978054 C7.88391562,1.8880313 8.9304242,1.45456053 10.1913875,1.45456053 C11.4521614,1.45456053 12.4988595,1.8880313 13.3911578,2.77978054 C14.2832666,3.67171909 14.7171017,4.71799521 14.7171017,5.97807151 C14.7171017,7.23852653 14.2832666,8.28461329 13.3911578,9.17655184 C12.4988595,10.0684904 11.4521614,10.5019612 10.1913875,10.5019612 C8.93080312,10.5019612 7.88429449,10.0683011 6.99180676,9.17655184 C6.09950851,8.28480265 5.66567344,7.23852653 5.66567344,5.97807151 C5.66567344,4.71799521 6.09950851,3.67171909 6.99180676,2.77978054 Z" id="Combined-Shape"></path>
									<path d="M15.8095238,13.6190476 C18.1238599,13.6190476 20,15.4951877 20,17.8095238 C20,20.1238599 18.1238599,22 15.8095238,22 L4.19047619,22 C1.8761401,22 2.83424429e-16,20.1238599 0,17.8095238 C-2.83424429e-16,15.4951877 1.8761401,13.6190476 4.19047619,13.6190476 L15.8095238,13.6190476 Z M15.802005,15.1904762 L4.19799499,15.1904762 C2.75153493,15.1904762 1.57894737,16.3630638 1.57894737,17.8095238 C1.57894737,19.2559839 2.75153493,20.4285714 4.19799499,20.4285714 L15.802005,20.4285714 C17.2484651,20.4285714 18.4210526,19.2559839 18.4210526,17.8095238 C18.4210526,16.3630638 17.2484651,15.1904762 15.802005,15.1904762 Z" id="Combined-Shape"></path>
							</g>
					</g>
			</g>
	</g>
</svg>

export const UserAvatar = () =>
<svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
	<g id="chat-claro" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
		<g id="Theme_Openai_CS" transform="translate(-903.000000, -363.000000)">
			<g id="Robot" transform="translate(903.000000, 363.000000)">
				<rect id="Rectangle" fill="#EF3F3F" x="0" y="0" width="30" height="30" rx="6"></rect>
				<g id="Group-4" transform="translate(5.000000, 4.000000)">
					<path d="M4,7 L16,7 C18.209139,7 20,8.790861 20,11 L20,16 C20,18.209139 18.209139,20 16,20 L16,20 L16,20 L14,23 L10,20 L4,20 C1.790861,20 2.705415e-16,18.209139 0,16 L0,11 C-2.705415e-16,8.790861 1.790861,7 4,7 Z" id="Rectangle" stroke="#FFFFFF" stroke-width="1.4" fill-rule="nonzero"></path>
					<circle id="Oval" stroke="#FFFFFF" stroke-width="1.5" cx="10.5" cy="2.5" r="2.5"></circle>
					<path d="M6,15.113 C6,15.113 7.08485164,17 10,17 C12.9151484,17 14,15.113 14,15.113" id="Stroke-1" stroke="#FFFFFF" stroke-linecap="round" stroke-linejoin="round"></path>
					<rect id="Rectangle" fill="#FFFFFF" x="4" y="11" width="5" height="1.5" rx="0.75"></rect>
					<rect id="Rectangle-Copy-4" fill="#FFFFFF" x="11" y="11" width="5" height="1.5" rx="0.75"></rect>
					<rect id="Rectangle-Copy-5" fill="#FFFFFF" transform="translate(10.500000, 5.750000) rotate(-270.000000) translate(-10.500000, -5.750000) " x="9" y="5" width="3" height="1.5" rx="0.75"></rect>
				</g>
			</g>
		</g>
	</g>
</svg>
export const ChatSanborns = () =>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="40.000000pt" height="20.000000pt" viewBox="0 0 456.000000 297.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,297.000000) scale(0.100000,-0.100000)"
fill="red" stroke="none">
<path d="M1545 2406 c-354 -125 -699 -317 -947 -525 -365 -307 -547 -679 -484
-991 41 -199 143 -352 327 -490 69 -51 232 -143 303 -170 l27 -10 -26 43 c-14
24 -25 56 -25 71 0 44 29 95 58 103 15 3 83 -9 171 -30 518 -128 1070 -110
1556 50 150 49 193 73 252 136 86 95 93 175 22 252 -50 55 -124 87 -285 125
-246 59 -387 77 -1059 141 -412 38 -466 51 -555 135 -177 166 -153 482 54 711
109 120 394 311 691 462 81 41 62 38 -80 -13z"/>
<path d="M2924 2436 c-71 -16 -198 -68 -234 -95 -21 -17 -22 -21 -10 -36 24
-29 243 -16 388 23 116 31 154 49 171 81 8 15 7 21 -7 26 -35 15 -246 15 -308
1z"/>
<path d="M3890 2343 c-114 -116 -357 -226 -591 -269 -129 -24 -409 -24 -539 0
-147 26 -245 54 -366 102 l-112 45 -153 -67 c-212 -91 -356 -166 -474 -246
-108 -73 -138 -98 -173 -142 -26 -34 -29 -91 -6 -125 37 -52 57 -54 504 -62
537 -10 738 -33 989 -116 226 -74 357 -157 414 -261 56 -103 56 -274 -2 -412
-71 -170 -260 -371 -462 -491 -38 -23 -68 -43 -66 -46 7 -6 234 80 381 146
343 153 625 345 843 575 279 295 389 621 302 902 -57 185 -171 330 -357 455
-41 27 -79 49 -85 48 -7 0 -28 -16 -47 -36z"/>
</g>
</svg>
export const ChatSanbornsLogin = () =>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="350.000000pt" height="150.000000pt" viewBox="0 0 456.000000 297.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,297.000000) scale(0.100000,-0.100000)"
fill="red" stroke="none">
<path d="M1545 2406 c-354 -125 -699 -317 -947 -525 -365 -307 -547 -679 -484
-991 41 -199 143 -352 327 -490 69 -51 232 -143 303 -170 l27 -10 -26 43 c-14
24 -25 56 -25 71 0 44 29 95 58 103 15 3 83 -9 171 -30 518 -128 1070 -110
1556 50 150 49 193 73 252 136 86 95 93 175 22 252 -50 55 -124 87 -285 125
-246 59 -387 77 -1059 141 -412 38 -466 51 -555 135 -177 166 -153 482 54 711
109 120 394 311 691 462 81 41 62 38 -80 -13z"/>
<path d="M2924 2436 c-71 -16 -198 -68 -234 -95 -21 -17 -22 -21 -10 -36 24
-29 243 -16 388 23 116 31 154 49 171 81 8 15 7 21 -7 26 -35 15 -246 15 -308
1z"/>
<path d="M3890 2343 c-114 -116 -357 -226 -591 -269 -129 -24 -409 -24 -539 0
-147 26 -245 54 -366 102 l-112 45 -153 -67 c-212 -91 -356 -166 -474 -246
-108 -73 -138 -98 -173 -142 -26 -34 -29 -91 -6 -125 37 -52 57 -54 504 -62
537 -10 738 -33 989 -116 226 -74 357 -157 414 -261 56 -103 56 -274 -2 -412
-71 -170 -260 -371 -462 -491 -38 -23 -68 -43 -66 -46 7 -6 234 80 381 146
343 153 625 345 843 575 279 295 389 621 302 902 -57 185 -171 330 -357 455
-41 27 -79 49 -85 48 -7 0 -28 -16 -47 -36z"/>
</g>
</svg>
