
const style = {
	width: "170px",
	"background-color": "#ffffff",
	"color": "#000000",
};
const SideMenu = ({ clearChat }) => 
<aside className="sidemenu">
	<button style={style} onClick={clearChat}>
		<span>+ </span>
		New Chat
	</button>
</aside>

export default SideMenu