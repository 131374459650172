import {UserAvatar, ChatAvatar,ChatSanborns} from '../../components/ChatAvatars'
import Typing from 'react-typing-effect';
// Primary Chat Window
const ChatBox = ({chatLog, setChatInput, handleSubmit, chatInput}) =>
	<section className="chatbox">
			<div className="chat-log">
				{chatLog.map((message, index) => (
					<ChatMessage key={index} message={message} />
				))}
			</div>
			<div className="chat-input-holder">
			<form className="form" onSubmit={handleSubmit}>
					<input
					rows="1"
					type="text"
					value={chatInput}
					onChange={(e)=> setChatInput(e.target.value)}
					className="chat-input-textarea" ></input>
					<button type="submit">Enviar</button>
					</form>
				</div>
			</section>

// Individual Chat Message
const ChatMessage = ({ message }) => {
	return (
		<div className={`chat-message ${message.user === "gpt" && "chatgpt"}`}>
		<div className="chat-message-center">
			<div className={`avatar ${message.user === "gpt" && "chatgpt"}`}>
				{message.user === "gpt" ? <ChatSanborns /> : <ChatAvatar />}
			</div>
			<div className="message">
				  {message.user === "gpt" ? < Typing speed="30"  eraseDelay="10000000000000000" text={message.message}/ >: <div>{message.message}</div>}
			</div>
		</div>
	</div>
	)
}

export default ChatBox
