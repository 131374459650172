import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import SideMenu from './SideMenu'
import ChatBox from './ChatBox'

import '../../styles/normal.css';
import './Chat.css';

function Chat() {
	let authToken = sessionStorage.getItem('T1ChatToken');
	const navigate = useNavigate();
	useEffect(() => {
		if (!authToken) navigate("/login");
	}, [])

	const [chatInput, setChatInput] = useState("");
	const [chatLog, setChatLog] = useState([{
		user: "gpt",
		message: "¡Bienvenido al chat de Grupo Sanborns!"
	}]);

	// clear chats
	function clearChat(){
		setChatLog([]);
	}

	async function handleSubmit(e){
		e.preventDefault();
		let chatLogNew = [...chatLog, { user: "me", message: `${chatInput}`} ]
		setChatInput("");
		setChatLog(chatLogNew)
		// fetch response to the api combining the chat log array of messages and seinding it as a message to localhost:3000 as a post
		const messages = chatLogNew.map((message) => message.message).join("\n")

		const response = await fetch("https://clarodev.mx:3080/", {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify({
				message: chatInput
			})
			});
		const data = await response.json();
		setChatLog([...chatLogNew, { user: "gpt", message: `${data.message}`} ])
		var scrollToTheBottomChatLog = document.getElementsByClassName("chat-log")[0];
		scrollToTheBottomChatLog.scrollTop = scrollToTheBottomChatLog.scrollHeight;
	}

	return (
		<div className="Chat view">
			<SideMenu
				clearChat={clearChat}
			/>
			<ChatBox
				chatInput={chatInput}
				chatLog={chatLog}
				setChatInput={setChatInput}
				handleSubmit={handleSubmit} />
		</div>
	);
}

export default Chat;
