import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Logout } from './Login';

function Navbar() {
	let authToken = sessionStorage.getItem('T1ChatToken');
	useEffect(() => {
	}, [])

	const navigate = useNavigate();

	if (!authToken) return (<></>);

	const style = {
		"textAlign": "right",
		"borderBottom": "4px solid #EF3F3F"
	};
	
	return (
		<div style={style} className="Navbar">
			<span>usuario@t1paginas.com</span>
			<Logout />
		</div>
	);
}

export default Navbar;